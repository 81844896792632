Fileupload.$inject = [
    '$compile',
    '$element',
    '$http',
    'allevaApi',
    'noty'];

export default function Fileupload($compile, $element, $http, allevaApi, noty) {
    const ctrl = this;
    ctrl.uploadDocumentList = [];
    ctrl.uploadedDocumentIds = [];
    ctrl.queuedForUpload = [];

    ctrl.$onInit = function () {
        getMasterCodeId()
            .then(result => {
                ctrl.masterCodeId = result;
                ctrl.initialized = true;
            });
    }

    ctrl.triggerFileInput = function (inputId) {
        document.getElementById(inputId).click();
    }

    ctrl.getValue = function () {
        let result = {
            Names: ctrl.uploadDocumentList.map(function (document) { return document.Name; }).join('\n'),
            Ids: ctrl.uploadedDocumentIds.join(',')
        }
        return result;
    }

    ctrl.setValue = function (value) {
        return new Promise((resolve, reject) => {
            if (!value) {
                ctrl.uploadedDocumentIds = [];
                return resolve();
            }
    
            ctrl.uploadedDocumentIds = value.split(',');
            
            // TODO - use a query to limit result set
            allevaApi.Clients.getDoc(ctrl.leadId)
                .then(response => {
                    response.forEach(file => {
                        if (ctrl.uploadedDocumentIds.includes(file.clientDocumentId.toString())) {
                            ctrl.uploadDocumentList.push({
                                Name: file.name,
                                Description: file.descsription,
                                FileName: file.fileName,
                                Status: "Saved",
                                Path: `${ALLEVA_API_HOST}/v2/documents/clients/${file.path}`
                            })
                        }
                    });
                    resolve(ctrl.uploadDocumentList.map(function (document) { return document.FileName; }).join('\n'));
                });
        });
    }

    function getMasterCodeId() {
       return allevaApi.MasterCodes.search({category: "DOCUMENTSTYPE"})
            .then(response => {
                const result = response.find(function (code) { return code.name == "AdvancedForm"; });
                return result ? result.id : null;
            });
    }

    this.upload = function () {
        return new Promise((resolve, reject) => {
            if (ctrl.uploadDocumentList.length == 0) {
                // this should never happen
                return resolve();
            }
    
            ctrl.queuedForUpload = ctrl.uploadDocumentList.filter(function (doc) {
    
                return doc.Status == "Queued";
            });
    
            if (ctrl.queuedForUpload.length == 0) {
                return resolve();
            }
    
            const params = {
                leadId: ctrl.leadId,
                name: ctrl.queuedForUpload[0].Name,
                description: '',
                typeId: ctrl.masterCodeId,
                uploadedDocuments: ctrl.queuedForUpload
            };
            
            return allevaApi.Clients.saveDoc(ctrl.leadId, params)
                .then(response => {
                    var IsErrorInSaving = false;
                    var errorMsg = "Not all files were able to be saved. Please review the attached files";
                    response.forEach(file => {
                        if (file.operationResult === 1) {
                            errorMsg += "<br/>" + file.messages[0];
                            IsErrorInSaving = true;
                        }
                        else {
                            ctrl.uploadedDocumentIds.push(file.id);
                        }
                    });
    
                    if (!IsErrorInSaving) {
                        let alert = "<h5>Dashboard</h5><p>Client documents saved sucessfully.</p>";
                        new noty({
                            text: alert,
                            timeout: 2000,
                            type: 'success'
                        }).show();
                        resolve();
                    }
                    else {
                        let alert = `<h5>Dashboard</h5><p>${errorMsg}</p>`;
                        new noty({
                            text: alert,
                            timeout: 60000,
                            type: 'error'
                        }).show();
                        resolve();
                    }
                })
                .then((result) => {
    
                    //  we aren't updating the datafacet yet,
                    //    which presents an issue with orphaned uploads if the form is never saved
                    
    
                    for (const file of ctrl.uploadDocumentList) {
                        // TODO: Handle individually failing files
    
                        file.Status = "Upload Complete";
                    }
                })
                .catch((ex) => {
                    for (const file of ctrl.uploadDocumentList) {
                        // TODO: Handle individually failing files
    
                        file.Status = "Server unavailable";
                    }
                    resolve();
                });
        });        
    }

    ctrl.deleteSavedFile = function (index) {
        // TODO - removed the document id from our list, but do not delete the actual file
    }

    ctrl.deleteUploadedFile = function (index) {
        // TODO - if the file has been uploaded, but not saved, delete the uploaded file
    }

    ctrl.deleteQueuedFile = function (index) {
        ctrl.uploadDocumentList.splice(index, 1);
    };
};

TreatmentPlanController.$inject = ['$scope', 'allevaApi', 'noty','$sce'];
export default function TreatmentPlanController($scope, allevaApi, noty, $sce) {
    /***************************
     * Params
     **************************/
    $scope.TPRDefaultSections = {
        Demographics : 'Demo_Graphy',
        MasterProblem: 'Master_Problem',
        SNAG: 'SNAG',
        SummaryProgress: 'Summary_Progress',
        DischargePlanning: 'Discharge_Planning',
        JustificationTreatment: 'Justification_Treatment',
        TreatmentPlan: 'Treatment_Plan',
        TPRNote: 'TPR_Note',
        TPRNextReview: 'TPR_Next_Review',
        Medications: 'Medications',
    };

     $scope._FamilyMemberType           = 2500;

    /***************************
     * Init
     **************************/
   

    /***************************
     * Actions
     **************************/
    $scope.validateSubmit = (form) => {
        if(!form.$valid){
            let alert = "<h5>Incomplete Application</h5><p>Please fill out all required fields.</p>";
            new noty({
            text: alert
            }).show();
            return false;
        }
        return true;
    };

    $scope.trustedHtml = (plainText) => {
        return $sce.trustAsHtml(plainText);
    }

    $scope.getCustomFormDetail = (section, customFormData) => {
        let tprCustomForm = customFormData.filter((form) => {
            return form.sectionCode == section.SectionCode;
        });
        if(tprCustomForm && tprCustomForm.length > 0){
            return tprCustomForm[0];
        }else{
            return null;
        }
    }

    $scope.signatureEntered = (result) => {
        submitSignature(result.participantSignature, result.type)
        .then(_ => {
            $scope.$ctrl.parent.closeForm();
        })
        .catch(_ => {
                let alert = "<h5>Error</h5><p>Could not submit signature. Please try again</p>";
                new noty({
                text: alert
            }).show();
            
        })
        .finally(_ => {
            result.finish();
        });
    }

    $scope.reviewSignatureEntered = (result) => {
        submitReviewSignature(result.participantSignature, result.type)
        .then(_ => {
            $scope.$ctrl.parent.closeForm();
        })
        .catch(_ => {
                let alert = "<h5>Error</h5><p>Could not submit signature. Please try again</p>";
                new noty({
                text: alert
            }).show();
            
        })
        .finally(_ => {
            result.finish();
        });
    }
        

    

    /***************************
     * CRUD
     **************************/
    // POST
    const submitSignature = (signature, type) => {
        let filters = {
        "tpid": $scope.$ctrl.parent.currentForm.id,
        "entityId": $scope.$ctrl.parent.currentApplicant.clientId,
        "data": signature,
        "type": type
        };

        return allevaApi.TreatmentPlan.submitSignature(filters)
        .then((response) => {
            return Promise.resolve(response);
        }).catch((error) => {
            return Promise.reject(error);
        });
    };

    const submitReviewSignature = (signature, type) => {
        let filters = {
        "tprid": $scope.$ctrl.parent.currentForm.tpReviewOverview.treatmentPlanReviewId,
        "entityId": $scope.$ctrl.parent.currentApplicant.clientId,
        "data": signature,
        "type": type
        };

        return allevaApi.TreatmentPlan.submitReviewSignature(filters)
        .then((response) => {
            return Promise.resolve(response);
        }).catch((error) => {
            return Promise.reject(error);
        });
    };
    
    /***************************
     * Helpers
     **************************/
}